import React from "react";
import {loadingSpin} from "./routingContainer";
import {Protected} from "./routingWrappers";

const Main = React.lazy(() => import("../mainPage/mainPage"));

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main/>
</Protected>

const UserTableContainer = React.lazy(() => import("../users/userListContainer"));

export const UserPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={['manage:users']}>
    <UserTableContainer/>
</Protected>
const UserFormContainer = React.lazy(() => import("../users/userForm/userFormContainer"));

export const UserFormPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={['manage:users']}>
    <UserFormContainer/>
</Protected>

const UserDetailsContainer = React.lazy(() => import("../users/userDetailsContainer"));
export const UserDetailsPage = () => <Protected loadingIndicator={loadingSpin} allowedForRoles={['manage:users']}>
    <UserDetailsContainer/>
</Protected>

const WarehouseTableContainer = React.lazy(() => import("../warehouse/warehouseListContainer"));

export const WarehousePage = () => <Protected loadingIndicator={loadingSpin}>
    <WarehouseTableContainer/>
</Protected>
const WarehouseFormContainer = React.lazy(() => import("../warehouse/warehouseForm/warehouseFormContainer"));

export const WarehouseFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <WarehouseFormContainer/>
</Protected>
const WarehouseDetailsContainer = React.lazy(() => import("../warehouse/warehouseDetailsContainer"));

export const WarehouseDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <WarehouseDetailsContainer/>
</Protected>
const ProductTableContainer = React.lazy(() => import("../product/productListContainer"));

export const ProductPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductTableContainer/>
</Protected>
const ProductHistoryContainer = React.lazy(() => import("../product/productHistoryContainer"));
export const ProductUpdateHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductHistoryContainer/>
</Protected>
const ProductFormContainer = React.lazy(() => import("../product/productForm/productFormContainer"));

export const ProductFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductFormContainer/>
</Protected>
const StockTableContainer = React.lazy(() => import("../stock/stockListContainer"));

export const StockPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockTableContainer/>
</Protected>
const StockHistoryContainer = React.lazy(() => import("../stock/stockHistoryContainer"));

export const StockHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockHistoryContainer/>
</Protected>

const StockOperationTableContainer = React.lazy(() => import("../stockOperation/stockOperationListContainer"));

export const StockOperationPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockOperationTableContainer/>
</Protected>
const StockOperationFormContainer = React.lazy(() => import("../stockOperation/stockOperationForm/stockOperationFormContainer"));

export const StockOperationFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockOperationFormContainer/>
</Protected>

const ProductionStockOperationFormContainer = React.lazy(() => import("../stockOperation/ProductionStockOperation/productionStockOperationFormContainer"));

export const ProductionStockOperationFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductionStockOperationFormContainer/>
</Protected>


const StockOperationDetails = React.lazy(() => import("../stockOperation/stockOperationDetails/stockOperationDetails"));

export const StockOperationDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockOperationDetails/>
</Protected>

const InternalStockOperationDetails = React.lazy(() => import("../internalStockOperation/internalStockOperationDetails/internalStockOperationDetails"));

export const InternalStockOperationDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <InternalStockOperationDetails/>
</Protected>

const ProductionStockOperationDetails = React.lazy(()=> import("../internalStockOperation/internalStockOperationDetails/productionStockOperationDetails"))

export const ProductionStockOperationDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductionStockOperationDetails/>
</Protected>

const ProcessStockOperationDetails = React.lazy(() => import("../productionProcess/productionProcessDetails/ProductionProcessDetails"))

export const ProcessStockOperationDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProcessStockOperationDetails/>
</Protected>

const VinificationOperationForm = React.lazy(() => import("../stockOperation/vinificationStockOperationForms/vinificationFormContainer"))

export const HarvestStockOperationFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <VinificationOperationForm/>
</Protected>


const CompanyDataContainer = React.lazy(() => import("../companyData/companyDataContainer"));

export const CompanyDataPage = () => <Protected loadingIndicator={loadingSpin}>
    <CompanyDataContainer/>
</Protected>
const CompanyDataFormContainer = React.lazy(() => import("../companyData/companyDataForm/companyDataFormContainer"));

export const CompanyDataFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <CompanyDataFormContainer/>
</Protected>
const CompanyDataHistoryContainer = React.lazy(() => import("../companyData/companyDataHistoryContainer"));

export const CompanyDataHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <CompanyDataHistoryContainer/>
</Protected>

const UnitOfMeasureTableContainer = React.lazy(() => import("../unitOfMeasure/unitOfMeasureListContainer"));

export const UnitOfMeasurePage = () => <Protected loadingIndicator={loadingSpin}>
    <UnitOfMeasureTableContainer/>
</Protected>
const UnitOfMeasureFormContainer = React.lazy(() => import("../unitOfMeasure/unitOfMeasureForm/unitOfMeasureFormContainer"));

export const UnitOfMeasureFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <UnitOfMeasureFormContainer/>
</Protected>
const StockOperationUpdateHistoryTableContainer = React.lazy(() => import("../stockOperation/stockOperationHistoryContainer"));

export const StockOperationUpdateHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockOperationUpdateHistoryTableContainer/>
</Protected>
const GeneralStockContainer = React.lazy(() => import("../stock/generalStock/generalStockContainer"));

export const GeneralStockPage = () => <Protected loadingIndicator={loadingSpin}>
    <GeneralStockContainer/>
</Protected>
const StockReservationHistoryContainer = React.lazy(() => import("../stock/stockReservationHistoryContainer"));

export const StockReservationHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <StockReservationHistoryContainer/>
</Protected>
const WarehouseOperationsCalendarContainer = React.lazy(() => import("../warehouseOperationsCalendar/warehouseOperationsCalendar"));

export const WarehouseOperationsCalendarPage = () => <Protected loadingIndicator={loadingSpin}>
    <WarehouseOperationsCalendarContainer/>
</Protected>

const ContractorTableContainer = React.lazy(() => import("../contractor/contractorListContainer"));

export const ContractorPage = () => <Protected loadingIndicator={loadingSpin}>
    <ContractorTableContainer/>
</Protected>
const ContractorFormContainer = React.lazy(() => import("../contractor/contractorForm/contractorFormContainer"));

export const ContractorFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <ContractorFormContainer/>
</Protected>
const ContractorHistoryContainer = React.lazy(() => import("../contractor/contractorHistoryContainer"));

export const ContractorHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <ContractorHistoryContainer/>
</Protected>
const ContractorOperationHistoryContainer = React.lazy(() => import("../contractor/contractorOperationHistoryContainer"));

export const ContractorOperationHistoryPage = () => <Protected loadingIndicator={loadingSpin}>
    <ContractorOperationHistoryContainer/>
</Protected>


const InventoryOperationTableContainer = React.lazy(() => import("../inventoryOperation/inventoryOperationListContainer"));

export const InventoryOperationPage = () => <Protected loadingIndicator={loadingSpin}>
    <InventoryOperationTableContainer />
</Protected>
const InventoryOperationFormContainer = React.lazy(() => import("../inventoryOperation/inventoryOperationForm/inventoryOperationFormContainer"));

export const InventoryOperationFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <InventoryOperationFormContainer />
</Protected>
const ProductionProcessTableContainer = React.lazy(() => import("../productionProcess/productionProcessListContainer"));

export const ProductionProcessPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductionProcessTableContainer />
</Protected>
const ProductionProcessFormContainer = React.lazy(() => import("../productionProcess/productionProcessForm/productionProcessFormContainer"));

export const ProductionProcessFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <ProductionProcessFormContainer />
</Protected>
const CropTableContainer = React.lazy(() => import("../crop/cropListContainer"));

export const CropPage = () => <Protected loadingIndicator={loadingSpin}>
    <CropTableContainer />
</Protected>
const CropFormContainer = React.lazy(() => import("../crop/cropForm/cropFormContainer"));

export const CropFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <CropFormContainer />
</Protected>
const StrainTableContainer = React.lazy(() => import("../strain/strainListContainer"));

export const StrainPage = () => <Protected loadingIndicator={loadingSpin}>
    <StrainTableContainer />
</Protected>
const StrainFormContainer = React.lazy(() => import("../strain/strainForm/strainFormContainer"));

export const StrainFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <StrainFormContainer />
</Protected>
const PhysicochemicalTestTableContainer = React.lazy(() => import("../physicochemicalTest/physicochemicalTestListContainer"));

export const PhysicochemicalTestPage = () => <Protected loadingIndicator={loadingSpin}>
    <PhysicochemicalTestTableContainer />
</Protected>
const PhysicochemicalTestFormContainer = React.lazy(() => import("../physicochemicalTest/physicochemicalTestForm/physicochemicalTestFormContainer"));

export const PhysicochemicalTestFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <PhysicochemicalTestFormContainer />
</Protected>
